import React from 'react';
import { IShowDetails, IStickyOffer } from './stickyOffer.model';

const observeHeight = (ref: React.MutableRefObject<HTMLDivElement>): void => {
  const body = document.getElementsByTagName('body')?.[0];
  if (!body) return;
  const bodyWidth = body.getBoundingClientRect()?.width;
  const bodyHeight = body.getBoundingClientRect()?.height;
  let wrapperNote = null;
  body.childNodes.forEach((note) => {
    const noteElement = note as HTMLElement;
    if (noteElement.clientWidth === bodyWidth && noteElement.clientHeight === bodyHeight) {
      wrapperNote = note;
    }
  });
  wrapperNote &&
    (wrapperNote as HTMLElement)?.childNodes.forEach((note) => {
      const noteElement = note as HTMLElement;
      if (noteElement.clientWidth === bodyWidth && noteElement.clientHeight >= bodyHeight) {
        noteElement.style.paddingBottom = `${ref.current.clientHeight}px`;
      }
    });
};

const renderDescription = (des: IStickyOffer['description'], showDetails: IShowDetails) => {
  if (typeof des === 'string') return des;

  return showDetails !== 'slide-up'
    ? des?.openProductDetails || 'MOSTRA DETTAGLI'
    : des?.closeProductDetails || 'NASCONDI DETTAGLI';
};

export { observeHeight, renderDescription };
